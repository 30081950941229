
.VehicleSidebar{}
.VehicleSidebar .ui.menu a{
}

.SidebarLeft.force-hide{
    display:none !important;
}


@media(max-width: 980px){
    .SidebarLeft.optional{
        display:none !important;
    }

    .MapDashboard .GroupSidebar{
        display: none !important;
    }

}

#root .MenuButton{
    font-weight: normal;
}

#root .MenuButton:hover{
}


