.TripTable td {
  white-space: nowrap;
  page-break-after: avoid;
  page-break-before: avoid;
}

.TripTable th{
  page-break-before: auto;
  page-break-after: avoid;
}
