.VehicleActions {

}


@media (min-width: 768px) {
    #root .VehicleActions .menu {
        right: 0;
        left: auto;
    }
}