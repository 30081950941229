
.MapPage{
    overflow-y:auto;
}

@media screen and (min-width: 1024px){

    .MapPage{
        max-height: none;
        overflow-y:hidden;
    }
}

