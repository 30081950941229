



.LoginPage .top-wrapper{
	height: 70vh;
	width:100%;
}

.LoginPage .top-wrapper svg{
	position:absolute;
	bottom:0;
}


.LoginPage .top-wrapper:after{
	content: "";
	position:absolute;
	bottom:0;
	width:100%;
	height:10px;
	background:white;
}