

@media print{
    @page
    {
        size: 210mm 297mm;
        margin: 0 0 5mm 0 ;  /* this affects the margin in the printer settings */
    }

    .DriveBookPage.page{
        margin: 0 32px;
    }


    #root .report-frame{
        max-height: 100%;
    }

    .ui.container{
        display: block;
        width: 100%;
        max-width: 95%;
        margin:0 auto 12mm;
    }

    th{
        break-after: avoid;
        break-before: auto;
    }

    body, .f5{
        font-size:12px !important;
    }

    .no-print{
        display:none;
    }

    .GroupSidebar{
        display: none;
    }


    .ui.button{
        display:none;
    }

    table div{
        font-size:10px !important;
    }

}