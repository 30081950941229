

.ContentPage{
}


.DomainSidebar{
    position:fixed;
    left:0;
    top:0;
    bottom:0;
    background:#1B1C1D;
    width:62px;
    height:100%;
}