
html, body, #root, .App, .HomePage {
    min-height: 100%;
}

.logo{
    /*background: rgba(255, 152, 0, 0.053);*/
}

#root {
}

#root .ui.search.dropdown .menu{
    max-height:15rem !important;
}

.PageContent{
    position:relative;
}

html, body, #root, .App, .app-wrapper{
    height:100%;
}

.outline{
    outline: none !important;
}

body{
}

.outline{
    outline: none !important;
}

.bg-grey{
    background:#efefef;
}

@media screen and (max-width:520px){
    a.ui.image.logo{
        display:none;
    }
}