


@media(max-width:980px){



}


.TripDetails.sticky{
    max-height: calc(100vh - 52px);
    overflow-y: auto;
}

.TripEntry{
    border: 2px solid transparent;
}

.TripEntry.active{
    border: 2px solid #00ACED;
}



.TripDetails{
    min-height: 240px;
}

.TripDetailSuccess{
    z-index:999;
    background: white;
    height:100%;
    max-height:100dvh;
    max-height:100vh;
    width:100%;
    top:0;
    left:0;
    right:0;
    bottom:0;
    padding-top:3rem;
    font-size:48px;
}