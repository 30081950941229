
#root .SearchAll input{
    width:100%;
    max-width:100%;
}

#root .GroupSidebar .SearchAll input[type="text"] {
    background:transparent;
    padding: 1rem 1rem;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    color: white;
}


#root .GroupSidebar .SearchAll.ui.input.icon i.icon {
    pointer-events: all;
    position:absolute;
    z-index: 9;
    cursor:pointer;
    top:0;
    bottom:0;
    right:0.5rem;
    display:flex;
    align-items: center;
    justify-content: center;
}