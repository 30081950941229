

.Tracker{
    transform: translate(-16px -16px);
    width: 32px;
    height: 32px;
    background: #1EBC30;
    border-radius:50px;
    border:3px solid white;
    box-shadow: 0 0 6px -3px rgba(0,0,0,0.2);
}

.ShareWelcome{
    overflow: hidden;
}

.Tracker .pulseAnimation{
    position: absolute;
    top:-3px;
    left:-3px;
    width:32px;
    height:32px;
    background: transparent;
    box-shadow: 0 0 0 0 rgba(30, 188,48, 1);
    transform: scale(1);
    border-radius: 90px;
    animation: trackerPulse 2s infinite;
}

.Tracker .text{
    position:absolute;
    top:-16px;
    left:36px;
    line-height: 27px;
    border-radius: 12px 12px 12px 0px;
    height:26px;
    background:#1EBC30;
    color:white;
    box-shadow: 0 0 3px 1px rgba(0,0,0,.2);
    white-space: nowrap;
    padding:0 9px ;
}


@keyframes trackerPulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(30, 188,48, .7);
    }

    70% {
        transform: scale(1.1);
        box-shadow: 0 0 0 10px rgba(30, 188,48, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(30, 188,48, 0);
    }
}