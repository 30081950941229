.fullscreen-map-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.fullscreen-map-container .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    background: rgba(0, 0, 0, 0);
    z-index: 9;
}

.fullscreen-map-container .overlay.active {
    background: rgba(0, 0, 0, 0.5);
}

.AutoDevice {
    cursor: pointer;
    position: absolute;
}

.AutoDevice .Maker .Text{
    width: 120px;
}

.AutoDevice.driving .parking-image {
	display: none;
}
.AutoDevice.parking .driving-image {
	display: none;
}
.AutoDevice svg.p-pin {
	transition: all 1200ms ease-out;
	width: 54px;
	height: 54px;
	top: -51px;
	left: -22px;
	position: absolute;
}
.AutoDevice svg.arrow-circle {
	transition: all 1200ms ease-out;
	width: 40px;
	height: 40px;
	left: -20px;
	top: -20px;
	position: absolute;
}


.AutoDevice.ePrivate .parking-image{
}

.AutoDevice .shadow {
	z-index: 45 !important;
}
.AutoDevice .marker {
	z-index: 50 !important;
}
.AutoDevice.driving .marker {
	z-index: 55 !important;
}
.AutoDevice.selected .marker {
    z-index: 60 !important;
}

.AutoDevice svg {
	pointer-events: none;
}
.AutoDevice svg .filler {
	pointer-events: all;
}

.AutoDevice.halting .filler {
	fill: #999;
}

.AutoDevice.selected .filler {
	fill: #ff3eb3ff !important;
}

.AutoDevice .Text{
    pointer-events: none;
    position:absolute;
    z-index: 10;
    top: -22px;
    left: 5px;
    white-space: nowrap;
    width:auto;
    background:rgba(0,0,0,0.6);
    padding: 0.4em .7em .25em 1.25em;
    color: white;
    border-radius: 24px 8px 8px 0px;
    font-family: "IBM Plex Sans", sans-serif;
}

.AutoDevice .Text .Plate{}
.AutoDevice .Text .Speed{
}

.AutoDevice .pin-content{
    color:white;
    text-align:center;
    position: absolute;
    width:27px;
    height:30px;
    line-height: 30px;
    font-size:22px;
    font-weight: bold;
    top:-44px;
    left: -14px;
    z-index: 9;
}

.AutoDevice.ePrivate .pin-content-private{
    display:block;
}

.AutoDevice.parking{
    filter:saturate(0);
}

.AutoDevice.parking.selected{
    filter:saturate(1);
}

.AutoDevice.ePrivate{
    filter:saturate(1);
}

.AutoDevice.eTracking .pin-content-private{
    display:none;
}


.AutoDevice.ePrivate .pin-content-parking{
    display:none;
}

.AutoDevice.eTracking .pin-content-parking{
    display:block;
}


.MapDashboard .sidebar{
    max-width:360px;
    right:12px;
    top:64px;
    bottom:24px;
}