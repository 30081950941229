.route-map {
	flex-grow: 1;
	min-height: 40vh;
}

.route-details th {
	font-style: italic;
	font-size: smaller;
	line-height: 0.7;
	color: #919191;
	text-align: left;
	font-weight: normal;
	padding-right: 0.7em;
}

.route-details td {
	font-weight: bold;
}
.route-details td em {
	font-weight: normal;
}

.route-entries {
	overflow-x: hidden;
	flex-grow: 1;
}

.route-entry {
	align-items: stretch;
	overflow: visible;
	margin-bottom: 1.75rem;
	cursor: pointer;
}

.route-entry.route-entry-first {
	margin-bottom: 0.8rem;
}

.route-entry.route-entry-last {
	margin-top: -0.9rem;
}

.route-entry.route-entry-driving {
	margin-top: -2rem;
	margin-bottom: 0;
	padding: 2rem 0;
}

.route-entry .route-entry-times,
.route-entry .route-entry-dots,
.route-entry .route-entry-titles {
	margin-right: 1em;
}

.route-entry .route-entry-times {
	align-items: flex-end;
	width: 4em;
	justify-content: center;
}

.route-entry .route-entry-index {
	display: flex !important;
	align-items: center;
	justify-content: center;
	width: 2.4em;
}

.circle-marker {
	margin-top: -0.825em;
	margin-left: -0.825em;
	font-family: "IBM Plex Sans", sans-serif;
	font-weight: bold;
}
.circle-marker,
.route-entry .route-entry-index .circle {
	width: 2.2em;
	height: 2.2em;
	border-radius: 50% !important;
	font-size: 13px !important;
	color: #fff;
	display: flex !important;
	align-items: center;
	justify-content: center;
	line-height: 1;
	font-weight: 400;
}
.circle-marker .icon,
.route-entry .route-entry-index .icon {
	margin-right: 0 !important;
}

.route-entry-parking-default .circle {
	background-color: #505050 !important;
}
.route-entry-parking-highlight .circle {
	background-color: #ff3eb3ff !important;
}
.route-entry-parking-muted .circle {
	background-color: #8e8e8e99 !important;
}
.route-entry-parking-muted .route-entry-dot {
	background-color: #ccc !important;
}
.route-entry-parking-muted {
	color: #aaaaaa66;
}
.route-entry-parking-muted .route-entry-duration {
	color: #aaaaaa44 !important;
}

.route-entry .route-entry-time {
	font-size: small;
	font-weight: bold;
}
.route-entry .route-entry-drive-distance,
.route-entry .route-entry-drive-duration {
	font-style: italic;
	font-size: smaller;
	line-height: 0.7;
}
.route-entry .route-entry-duration {
	font-style: italic;
	font-size: x-small;
	line-height: 0.7;
	color: #919191;
}
.route-entry .route-entry-dots {
	width: 1em;
	align-items: center;
	justify-content: center;
}
.route-entry .route-entry-dot {
	width: 1em;
	height: 1em;
	border-radius: 0.5em;
	background-color: #505050;
	z-index: 2;
}
.route-entry .route-entry-haltline {
	min-height: 1em;
	flex-grow: 1;
	width: 5px;
	background-image: linear-gradient(to bottom, transparent 50%, #d7d7d7 50%);
	background-size: 100% 3px;
}
.route-entry .route-entry-driveline {
	margin-top: -2.2rem;
	margin-bottom: -2.2rem;
	flex-grow: 1;
	width: 5px;
	z-index: 1;
	cursor: pointer;
}

.route-entry.route-entry-driving-default .route-entry-driveline {
	background-color: #00d6e6;
}
.route-entry.route-entry-driving-highlight {
	color: #ff3eb3ff;
}
.route-entry.route-entry-driving-highlight .route-entry-driveline {
	background-color: #ff3eb3ff;
}
.route-entry.route-entry-driving-muted {
	color: #aaaaaa66;
}
.route-entry.route-entry-driving-muted .route-entry-drive-duration {
	color: #aaaaaa44;
}
.route-entry.route-entry-driving-muted .route-entry-driveline {
	background-color: #aaaaaa66;
}

.map-marker-parking {
	cursor: pointer;
}

.map-marker-parking-default .circle-marker {
	background-color: #505050;
}
.map-marker-parking-highlight .circle-marker {
	background-color: #ff3eb3;
}
.map-marker-parking-muted .circle-marker {
	background-color: #8e8e8e99;
}

.route-entry .route-entry-titles {
	justify-content: center;
}
.route-entry .route-entry-suptitle {
	font-weight: bold;
}
.route-entry .route-entry-subtitle {
	font-size: small;
}

.RouteSegments {
	position: relative;
}

.RouteSegments .ui.label.circular i {
	position: relative;
	margin: 0;
}

.RouteSegments .timeline-pole.background {
	background: #f2f2f8;
	display: block;
	width: 5px;
	height: 100%;
	position: absolute;
	left: 80px;
	z-index: 3;
}

.RouteSegments {
	background: #f5f5f5;
}
