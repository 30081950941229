th .header-align-right {
	text-align: right;
}
th .header-align-left {
	text-align: left;
}

th .header-align-right .marker,
th .header-align-right .header {
	float: right;
}
th .header-align-left .marker,
th .header-align-left .header {
	float: left;
}

th .header-align-right .marker {
	margin-right: 0.6em;
}
th .header-align-left .marker {
	margin-left: 0.6em;
}

th .header {
	display: inline-block;
	position: relative;
}
th .subheader {
	font-size: xx-small;
	font-weight: lighter;
	position: absolute;
	bottom: -1.5em;
	right: 0;
}

th .marker {
	margin-top: 0.1em;
}

th .marker.marker-unsorted {
	visibility: hidden;
}