.SimpleMap .svg-inline--fa {
}



.AutoDevice.is-parking .parking{
    display:block;
}

.AutoDevice.is-parking .driving{
    display:none;
}

.AutoDevice.is-driving .parking{
    display:none;
}

.AutoDevice.is-driving .driving{
    display:block;
}