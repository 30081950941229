.RegisterDemoModal{

}
.RegisterDemoModal .ui.container{

}
.RegisterDemoModal .ui.container .segment{
    filter: drop-shadow(0 4px 10px rgba(0,0,0,0.55));
    background: url("/stock/register-demo-modal-tinted.jpg");
    background-position:center;
    background-size: cover;
    border:0;
    min-height:80vh;
    align-items: flex-end;
    display:flex;
}

.RegisterDemoModal .ui.container .segment>div{
    width:100%;
}

@media screen and (min-width: 520px){

    .RegisterDemoModal .ui.container .segment{
        min-height:720px;
    }

    .RegisterDemoModal .ui.container .segment>div{
        width:100%;
    }
}