.Note{
    border: 1px solid #efefef;
    margin-bottom: 1em;
    position:relative;
}

.Note:after{
    content: " ";
    display:block;
    position:absolute;
    height:1em;
    width:8px;
    left:1em;
    bottom: -1em;
    bottom: calc( -1em - 1px );
    background: #efefef;
}

.Note:last-child:after{
    display:none;
}


.bg-silver .ui.menu{
    background:#f5f5f5;
}