.BuyPage {
    /*z-index: 2; */
}

.BuyPage .sidebar {
    position: relative;
    z-index: 100;
}

.BuyPage .sidebar:after {
    z-index: -1;
    background: #f5f5f5;
    box-shadow: 1px 0 0 #e1e1e1 inset;
    height: 100%;
    min-height: 100vh;
    width: 300%;
    position: absolute;
    content: " ";
    top: 0;
}


@media (max-width: 922px) {
    .BuyPage .ui.grid {
        flex-direction: column;
    }

    .BuyPage .ui.grid .column {
        width: 100% !important;
    }

    .BuyPage .sidebar {
        width: 100%;
    }
}