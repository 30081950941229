
body, html, #root {
    font-family: "IBM Plex Sans", sans-serif;
}


.box-shadow {
    box-shadow: 0 0 15px -3px rgba(0, 0, 0, .25);
}
.box-shadow-soft {
    box-shadow: 0 0 12px -3px rgba(0, 0, 0, .15);
}

.no-pointer-events {
    pointer-events: none;
}


.ui.basic.table th {
    color: rgba(98, 98, 147, 0.7);
    text-transform: uppercase;
    padding-top: 1.2rem !important;
    padding-bottom: 1.2rem;
}


.ui.container.container-raised {
    background: #ffff;
    padding: 1rem 2rem;
    border-radius: 9px;
    box-shadow: 0 0 1px 0 rgba(43, 65, 86, 0.12), 0 2px 8px 0 rgba(43, 65, 86, 0.12);
}

.ui.circular.icon.left.floated + .ui.header.small {
    margin-top: 12px !important;
    padding-bottom: 1em;
}

.drop-shadow {
    filter: drop-shadow(0 12px 12px rgba(0, 0, 0, 0.25));
}

.bg-gradient {
    /*background-image: linear-gradient(147deg, #133046 0%, #15959F 76%);*/
    background: #133046;
}

.bg-gradient-silver {
    background: #ffb300; /* fallback for old browsers */
    background: linear-gradient(to top, #ffb300, #FFEB3B);

}

a.ui.segment {
    display: block;
}


.delay-500 {
    animation-delay: 500ms;
}

.duration-800 {
    animation-duration: 800ms;
}


.dropzone div {
    margin: 0;
    padding: 2em;
    text-align: center;
    border: 2px dashed #aeaeae;
    cursor: pointer;
}

.ui.inverted.dimmer {
    background-color: rgba(255, 255, 255, 0.6);
}

.transition-300 {
    transition: all 300ms ease-in-out;
}

#root .form .ui.input input {
    border: 1px solid #ffffff;
    padding: 1rem;
    border-radius: 0;
    background: #fafaff;
    border-bottom: 2px solid #d8d5d5;
}

.form .ui.input input::placeholder {
    color: rgba(0, 0, 0, 0.4);
}

.ui.form .field > label {
    font-weight: normal;
    color: rgba(0, 0, 0, 0.7);
}

.ui.segment.LoginSegment {
    filter: drop-shadow(0 10px 15px rgba(68, 54, 0, 0.21));
    border-radius: 3px;
}

#root .MapDashboard .ui.container,
#root .MapPage .ui.container {
    max-width: 100% !important;
    overflow: hidden;
}


#root .ui.button {
}

#root .ui.button.inverted {
    background: white;
    color: rgba(0, 0, 19, 0.8);
}

.overflow-scrolling-touch {
    overflow-scrolling: touch;
}

.sticky {
    position: sticky;
}

.DriverImage img {
    border-radius: 100%;
    border: 7px solid white;
}


@media ( min-width: 1420px) {
    .ui.container {
        width: 1380px;
    }
}