
.TimeNavigation {
}

.TimeNavigation .SingleDatePickerInput .DateInput_input {
    font-size: 16px;
    font-weight: 200;
}

.DayPicker_weekHeader_li {
    top: -5px;
    position: relative;
}

.CalendarDay {
    position: relative;
}

.CalendarDay .CalendarDayWeekNumber {
    position: absolute;
    left: 2px;
    top: -2px;
    font-size: 9px;
    display: none;
    color: rgba(0, 0, 0, 0.5)
}

.CalendarMonth_table tr td.CalendarDay:first-child .CalendarDayWeekNumber {
    display: block;
}

.DateRangePicker__picker, .SingleDatePicker__picker {
    z-index: 51;
    pointer-events: all;
}
.tether-element {
   z-index: 50;
   pointer-events: none;
}

#root .SingleDatePicker_picker{
    z-index:12;
}
@media screen and (max-width: 768px){
    .TimeNavigation .ui.button{
        font-size:12px !important;
    }

}